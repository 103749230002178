.ph_login {
    width: 100%;
    text-align: center;
    margin: 0 auto;
    padding: 20px;
}

.ph_login img {
    width: 100px;
    height: 100px;
    margin-bottom: 30px;
}

.ph_login h2 {
    font-size: 2em;
}

.ph_login p {
    color: #cccccc;
    font-weight: 600;
}

._wapper {
    display: none;
    margin-top: 40px;
}

.show {
    display: block;
}

.otp_wapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    margin-top: 20px;
}

.otp_wapper input {
    width: 16.6%;
    height: 40px;
    padding: 0;
    margin-right: 25px;
    text-align: center;
    border: 1px solid gray;
    border-radius: 5px;
}

.otp_wapper input:last-child{
    margin-right: 0;
}

.otp_wapper input::-webkit-inner-spin-button, 
.otp_wapper input::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
}

.otp_wapper input:focus,
.otp_wapper input.focus{
    border-color: green;
    outline: none;
    box-shadow: none;
}

.btn_wapper {
    margin-top: 20px;
}

.btn_wapper button {
    background-color: #4c69ba;
}