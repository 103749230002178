
.product_inner {
    /* border: 1px solid;
    border-color: #ebebeb; */
    border: none;
    border-radius: 5px;
    box-shadow: 0px 0px 6px 1px #dddddd;
    padding: 0 !important;
    margin: 5px 2px 5px 2px;
}

.product_inner .product_head {
    position: relative;
    overflow: hidden;
    margin-bottom: 10px;
}

.product_inner .product_head .product_image {    
    width: 100%;
    height: 0;
    position: relative;
    overflow: hidden;
    /* padding: 28.125% 0; */
    padding: 35% 0;
}

.product_inner .product_head .product_image img {    
    /* display: block;
    margin: 0 auto;
    min-width: 169px;
    min-height: 169px;
    width: 100%;
    border: none;
    -webkit-border-radius: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    transition: opacity 1.5s cubic-bezier(.19,1,.22,1) 0ms; */

    
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
}

.product_inner .product_content {
    padding: 0 15px;
}

.product_inner .product_content .product_title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 1em;
    color: black;
    font-weight: 600;
}

.product_inner .product_content span.price {
    display: inline-block;
    width: 100%;
    font-size: 0.8em;
    color: #009688;
    margin-bottom: 15px;
}

.product_inner .action {
    text-align: center;
    padding: 15px;
}

.product_inner .action button {
    margin: 5px 3px;
    border: none;
    padding: 8px 15px;
    border-radius: 5px;
    color: #ffffff;
    cursor: pointer;
}

.product_inner .action button:focus {
    outline: none;
}

.product_inner .action button::before {
    font-family: 'Fontawesome';
    color: #000000;
}

.product_inner .action button.add_to_cart {
    background-color: transparent;
}

.product_inner .action button.add_to_cart::before {
    content: '\f217';
}

.product_inner .action button.add_to_wishlist {
    background-color: transparent;
}

.product_inner .action button.add_to_wishlist::before {
    content: '\f08a';
}

.product_inner .action button.add_to_wishlist.active::before {
    content: '\f004';
    color: #007bff;
}

@media screen and (min-width: 786px) {
    
    .product_inner .product_head .product_image {
        width: 100%;
        height: 200px;
    }

    .product_inner .product_head .product_image img {    
        display: block;
        margin: 0 auto;
        height: auto;
        max-width: 100%;
        border: none;
        -webkit-border-radius: 0;
        border-radius: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
        transition: opacity 1.5s cubic-bezier(.19,1,.22,1) 0ms;
    }

    .pad {
        padding: 0 10px !important;
    }

}


/* Grid */

.product_list {
    width: 100%;
    display: block;
}

.product_list .product_inner {
    display: flex;
    flex-direction: row;
    position: relative;
}

.product_list .product_list_head {
    position: relative;
    overflow: hidden;
    flex: 0 0 40%;
    max-width: 40%;
}

.product_list .product_list_head img {
    width: 100%;
}

.product_list .product_list_content_cont {
    display: flex;
    flex-direction: column;
    width: 60%;
    padding-left: 10px;
}

.product_list .product_list_content {
    width: 100%;
}

.product_list .product_list_content .product_title {
    display: inline-block;
    padding-top: 10px;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 1.5em;
    color: black;
    font-weight: 600;
}

.product_inner .product_list_content span.price {
    display: inline-block;
    width: 100%;
    font-size: 0.8em;
    color: #009688;
}

.product_list .product_list_action {
    padding: 0;
    justify-content: flex-end;
    text-align: left;
    position:absolute;                  
    bottom:0;
}

.discount {
    position: absolute;
    display: inline-block;
    background-color: #f57f17;
    padding: 3px 10px 3px 5px;
    top: 5%;
    color: #ffffff;
}

.discount::after {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    right: 0;
    top: 0;
    margin: 0;
    padding: 0;
    margin-right: -14px;
    border-top: 15px solid #f57f17;
    border-right: 15px solid transparent;
    border-bottom: 15px solid #f57f17;
}