.backdrop {
  position: absolute;
  top: 50%;
  left: 45%;
  z-index: 9999;
}

.search_section {
  display: inline-block;
  width: 100%;
  /* padding: 10px 10px; */
}

.search_section input {
  width: 100%;
  border: none;
  border-left: 1px solid #ccc;
  /* border-radius: 5px; */
  padding: 10px;
  /* box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24); */
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  /* -webkit-box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24); */
  -webkit-appearance: none;
}

/* .search_section input:hover, .search_section input:focus {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
} */

.search_section input:focus {
  outline: none;
}

.search-icon {
  display: inline-block;
  margin-left: -30px;
  color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

.border_bottom {
  border-bottom: 1px solid #ccc;
}

.t_center {
  text-align: center;
}

.t_left {
  text-align: left !important;
}

.app_bar {
  clear: both;
  width: 100%;
  position: fixed;
  z-index: 999999;
  background-color: #ffffff;
  margin: 0;
  padding: 0 20px 0 10px;
  align-items: center;
  max-width: 800px;
}

.app_bar button {
  border: none;
  padding: 10px;
  background-color: transparent;
  cursor: pointer;
}

.app_bar button:focus {
  outline: none;
}

.app_bar button i {
  cursor: pointer;
  font-size: 1.5em;
  color: rgb(63, 62, 62);
}

@media screen and (min-width: 786px) {
    .app_bar, .search_section {
        /* display: none; */
    }
}


.app_bar .col_2 button {
  width: 100% !important;
}

.app_bar .t_left button {
  width: auto !important;
}

.app_bar .col_2 button:hover {
  background-color: #ffffff;
}