.section-title {
    padding-top: 80px;
    padding-bottom: 80px;
}

.section-title h2 {
    text-align: center;
    margin-bottom: 8px;
    text-transform: capitalize;
}

.contact_container p {
    color: #969696;
}

.icon_inner {
    width: 85px;
    height: 85px;
    font-size: 38px;
    border-radius: 75px;
    overflow: hidden;
    border-width: 2px;
    border-style: solid;
    border-color: #009688;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}

.icon_inner i {
    color: #009688;
}

.box_contact {
    width: 100%;
    margin-left: 20px;
    width: 50%;
}

.contact_container .contact {
    display: flex;
    margin-bottom: 10px;
}

.contact_summery_container {
    display: flex;
    margin: 0 auto;
    padding-bottom: 80px;
    border-bottom: 1px solid #969696;
}
