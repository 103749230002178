.profile_container {
    position: absolute;
    width: 100%;
    height: 200px;
    background-color: #534bae;
    /* max-width: 800px; */
}

.wrapper {
    position: absolute;
    width: 100%;
    /* max-width: 800px; */
}

 .profile_detail {
    position: relative;
    width: 90%;
    margin: 0 auto;
    border-radius: 10px;
    border: 1px solid #ccc;
    background-color: #ffffff;
    margin-top: 80px;
    text-align: center;
    padding: 20px;
}

 .profile_detail .img_container {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    
    /* max-width: 960px;
    margin: 30px auto;
    padding: 20px; */
}

 /* .profile_detail .img_container img {
    display: inline-block;
    width: 90px;
    height: 90px;
    border-radius: 50%;
} */

 .profile_detail p {
    display: block;
    /* padding: 5px; */
    font-weight: 600;
    font-size: 1.2em;
    overflow-wrap: break-word ;
    word-wrap: break-word;
    hyphens: auto;
    margin-bottom: 0;
    /* text-emphasis: unset; */
}

.white_space {
    width: 100%;
    height: 50px;
    padding: 0 20px;
}

 .profile_detail button {
    position: absolute;
    right: 20px;
}

.wrapper button {
    border: none;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #534bae;
    color: #ffffff;
    cursor: pointer;
}

 button:hover {
    background-color: #1a237e;
}

 button:focus {
    outline: none;
}

 .settings {
    width: 100%;
    padding: 30px;
    background-color: #fff;
}

 .settings .list {
    display: inline-block;
    width: 100%;
    margin-bottom: 20px;
    cursor: pointer;
    color: #000000;
}

 .settings .list:hover {
    color: #1a237e;
}

 .settings span {
    display: inline-block;
    padding-left: 20px;
    font-weight: 500;
}


.avatar-edit {
    position: absolute;
    right: 12px;
    z-index: 1;
    top: 10px;
}
.avatar-edit input {
    display: none;
}


.avatar-edit label {
    display: inline-block;
    width: 50px;
    height: 50px;
    margin-bottom: 0;
    border-radius: 100%;
    background: #ffffff;
    border: 1px solid transparent;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
    cursor: pointer;
    font-weight: normal;
    transition: all .2s ease-in-out;
    margin-right: -30px;
}
.avatar-edit label:hover {
    background: #f1f1f1;
    border-color: #d6d6d6;
}

.avatar-edit label::after {
    content: "\f040";
    font-family: 'FontAwesome';
    font-size: 1.2em;
    color: #757575;
    position: absolute;
    top: 12px;
    left: 17px;
    right: 0;
    text-align: center;
    margin: auto;
}

.avatar-preview {
    width: 150px;
    height: 150px;
    position: relative;
    border-radius: 100%;
    border: 6px solid #F8F8F8;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    margin: 0 auto;
    margin-bottom: 20px;
}
.avatar-preview > img {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.crop-modal {
    width: 100%;
}

.crop-modal img {
    width: 100%;
}