.search_filter {
    position: fixed;
    padding: 0 10px;
    z-index: 99999;
    background-color: #ffffff;
    width: 100%;
    box-shadow: 0 14px 12px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    max-width: 800px;
}

.search_filter .close {
    width: 100%;
}

.search_filter .close span {
    display: inline-block;
    padding: 10px;
    cursor: pointer;
}

.search_filter .close span.filt {
    width: 80%;
}

.search_filter .close span.close_icon {
    width: 20%;
    text-align: right;
    font-size: 0.8em;
}

.search_filter .select_box {
    clear: both;
    width: 100%;
    padding: 10px 10px;
}