.footer {
    position: fixed;
    bottom: 0;
    z-index: 996;
    border-top: 1px solid;
    border-top-color: #ebebeb;
    width: 100%;
    max-width: 800px;
}

.footer::before {
    content: '';
    display: table;
}

.footer::after {
    clear: both;
}

.footer ul {
    list-style: none;
    text-decoration: none;
    display: inline-block;
    margin: 0;
    padding: 0;
    width: 100%;
}

.footer ul li {
    display: inline-block;
    width: 25%;
    text-align: center;
}

.footer ul li a {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    padding-bottom: 5px;
    background-color: #fff;
    height: 4.235801032em;
    position: relative;
    z-index: 999;
    border-right: 1px solid;
    border-right-color: #ebebeb;
    color: #000000;
    text-decoration: none;
}

.footer ul li a:focus {
    color: #009688;
}

.footer ul li a::before {
    font-family: "FontAwesome";
    font-size: 1.618em;
    vertical-align: middle;
    font-weight: 400;
    font-style: normal;
    line-height: 2;
    cursor: pointer;
}

.footer ul li a span {
    font-weight: 600;
    font-size: 12px;
    color: #000000;
}

.footer ul li a.home::before {
    content: "\f015";
    text-decoration: none !important;
}

.footer ul li a.discover::before {
    content: "\f2d5";
    text-decoration: none !important;
}

.footer ul li a.collection::before {
    content: "\f2b5";
    text-decoration: none !important;
}

.footer ul li a.my_account::before {
    content: "\f2c3";
    text-decoration: none !important;
}

.footer ul li a.search::before {
    content: "\f002";
    text-decoration: none !important;
}

.footer ul li a.message::before {
    content: "\f27b";
    text-decoration: none !important;
}

.product_search {
    margin-bottom: 0;
    background-color: #ffffff;
    position: absolute;
    bottom: -2em;
    left: 0;
    right: 0;
    transition: all, ease, 0.2s;
    padding: 1em;
    z-index: 1;
    display: block;
}

.product_search.active {
    margin-bottom: 100px;
}

.product_search::after {
    font-family: "Fontawesome";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    line-height: 1;
    vertical-align: middle;
    line-height: inherit;
    content: "\f002";
    position: absolute;
    top: 2.1em;
    left: 1.4em;
    line-height: 1;
}

.product_search input {
    padding-left: 2.9em;
    padding-right: 7em;
    padding: 1em 1.41575em;
    line-height: 1;
    width: 100%;
    color: #b0b0b0;
    border: 2px solid;
    border-color: #ebebeb;
}

.product_search button {
    /* height: calc(100% - 16px); */
    height: 30px;
    width: 100px;
    display: inline-block;
    line-height: 0;
    position: absolute;
    top: 30px;
    right: 28px;

    border: 2px solid;
    border-radius: 3px;
    background: none;
    background-color: #ef3636;
    border-color: #ef3636;
    color: #fff;
    cursor: pointer;
    padding: 5px 10px;
    text-decoration: none;
    font-weight: 700;
    text-shadow: none;
    display: inline-block;
    -webkit-appearance: none;
}

@media screen and (min-width: 768px) {
    .footer {
        /* display: none; */
    }
}