.header-logo {
  width: 50px;
  height: 50px;
  display: inline-block;
}

.header-logo img {
  width: 100%;
}

.header-text {
  display: inline-block;
  padding-left: 10px;
  font-weight: 500;
  font-size: 18px;

}

.headerStyle {
  position: fixed;
  top: 0;
  width: 100%;
  border-bottom: 1px solid;
  border-bottom-color: #ebebeb;
  background-position: center center;
  z-index: 99999;
  background-color: #fff;
  max-width: 800px;
}

.container {
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
}

.headerLeft {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-right-color: #ebebeb;
}

.siteBranding {
  padding: 4.5px;
  margin-bottom: 0;
  display: block;
  clear: both;
}

.customLogoLink {
  display: block;
  margin-bottom: 0;
}

.siteHeaderCartMobile {
  padding-right: 15px;
  list-style-type: none;
  margin-left: 0;
  padding: 0;
  margin-bottom: 0;
}

.siteHeaderCartMobile,
.menuMobileNavButton {
  cursor: pointer;
}

.cartContents {
  position: relative;
  padding: 0 15px;
}

.menuIcon {
  font-size: 1.5em;
  color: rgb(63, 62, 62);
}

.shopping_cart {
  font-size: 1.7em;
}

.count {
  font-weight: 500;
  font-style: normal;
  font-size: 11px;
  position: absolute;
  top: 0;
  right: 50%;
  width: 16px;
  height: 16px;
  line-height: 16px;
  text-align: center;
  margin-top: -13px;
  margin-right: -23px;
  color: #fff;
  background: #009688;
  border-radius: 50%;
}

.menuMobileNavButton {
  order: -1;
  min-width: 42px;
  display: block;
  font-size: 15px;
  color: #000;
}

.menu_search {
  display: none;
}

.laptop {
  display: none;
}

.mobile {
  display: block;
}

@media screen and (min-width: 768px) {
  .menu_search {
    display: inline-block;
    width: 50%;
  }

  .menu_search input {
    width: 90%;
    /* border: 1px sold #cccccc; */
    border: none;
    padding: 10px;
    background-color: #f2f2f2;
  }

  .menu_search button {
    border: none;
    background-color: #009688;
    margin-left: -110px;
    cursor: pointer;
    padding: 5px 20px;
    border-radius: 5px;
    color: #fff;
  }

  .menu_search button::after {
    font-family: 'Fontawesome';
    content: ' \f002';
  }

  .menu_search button:hover {
    color: #000000;
  }

  .menu_search input:focus,
  .menu_search button:focus {
    outline: none;
  }

  .laptop {
    display: inline-block;
  }

  .mobile {
    display: none;
  }
}

.zInd {
  z-index: 99999999;
}
