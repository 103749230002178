* {
    box-sizing: border-box;
    font-size: 16px;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}

a {
    color: #009688;
    text-decoration: none;
}

ul {
    margin: 0;
    padding: 0;
}

ul li {
    list-style: none;
}

/* .sep_footer {
    margin-bottom: 30px;
    padding-bottom: 20px;
    border-bottom: 1px solid #EDEDED
} */

.ms_row {
    display: flex;
    flex-wrap: wrap;
}

.ms_col {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
    position: relative;
    width: 100%;
}

.col_1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.col_2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
    padding-left: 0 !important;
    padding-right: 0 !important;
    text-align: center;
}

.col_3 {
    flex: 0 0 25%;
    max-width: 25%;
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.col_4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.col_5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.col_6 {
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.col_7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.col_8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
    padding-left: 0 !important;
    padding-right: 0 !important;
    
}
.col_9 {
    flex: 0 0 75%;
    max-width: 75%;    
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.col_10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.col_11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;  
    padding-left: 0 !important;
    padding-right: 0 !important;  
}
.col_12 {
    flex: 0 0 100%;
    max-width: 100%;  
    padding-left: 0 !important;
    padding-right: 0 !important;  
}

.t_center {
    text-align: center;
}

.not-found {
    position: absolute;
    /* top: 45%;
    width: 92%; */
    /* left: ; */
    top: 50%;
    width: 100%;
    margin: 0;
    padding: 0;
    margin: 0 auto;
    text-align: center;
}

.not-found-letter {
    font-size: 1.5em;
    font-weight: 500;
    color: #cccccc;
}

.not-found .btn.btn-solid {
    border: 1px solid rgba(0, 0, 0, 0.08);
    cursor: pointer;
}

.d-block {
    display: block!important;
}

.pdt20 {
    padding-top: 20px !important;
}

@media screen and (min-width: 768px) { 
    .not-found {
        width: 100%;
        /* max-width: 800px; */
    }
}

@media  screen and (min-width: 850px) {
    .hide-on-web {
        display: none !important;
    }

    .show-on-web {
        display: flex !important;
        max-width: 800px;
        align-items: center;
    }
}