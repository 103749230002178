.section-ptb {
    padding: 10px 0;
}

.site-content .table {
    border: 1px solid #e8e8e8;
    border-radius: 0;
}

.cart-table thead th {
    display: table-cell;
    background: 0 0;
    border: none;
    padding: 15px 10px;
    text-align: left;
    font-weight: 700;
    color: #323232;
}

.cart-table thead th.product-thumbnail {
    width: 100px;
}

.cart-table thead td {
    vertical-align: middle;
    line-height: 1.5em;
    text-align: left;
    border: none;
    border-top: 1px solid #e7e7e7;
    padding: 16px 12px;
    color: #606060;
}

.cart-table thead td.product-remove {
    text-align: center;
    width: 50px;
}

.cart-table thead td.product-remove .remove {
    display: inline-block;
    font-size: 0;
    color: #969696;
}

.cart-table thead td.product-remove .remove:before {
    content: "\f014";
    position: relative;
    left: 6px;
    top: 0;
    font-family: FontAwesome;
    font-size: 16px;
    font-weight: 400;
}

.cart-table thead td.product-thumbnail a {
    display: block;
    border: none;
    width: 80px;
}

.cart-table thead td.product-thumbnail a img {
    width: 70px;
    height: auto;
}

.cart-table thead td.product-quantity {
    min-width: 80px;
}

.cart-table thead td.product-quantity .quantity {
    position: relative;
    padding: 0 24px;
    display: inline-block;
    width: auto;
}

.cart-table thead td.product-quantity .quantity .screen-reader-text {
    clip: rect(1px, 1px, 1px, 1px);
    position: absolute !important;
    white-space: nowrap;
    height: 0;
    width: 1px;
    overflow: hidden;
    word-wrap: normal !important;
}

.cart-table thead td.product-quantity .quantity input {
    width: 50px !important;
    height: 45px;
    line-height: 1.65;
    float: left;
    display: block;
    padding: 0 10px;
    margin: 0;
    font-size: 16px;
    color: #969696;
    border: 1px solid #e6e6e6;
    background: #f8f8f8;
    text-align: center;
}

.cart-table thead td.product-quantity .quantity .quantity-button {
    position: relative;
    cursor: pointer;
    background: #fff;
    border: 1px solid #e6e6e6;
    width: 20px;
    text-align: center;
    color: #969696;
    font-size: 20px;
    font-family: "Trebuchet MS", Helvetica, sans-serif !important;
    line-height: 45px;
    -webkit-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.cart-table thead td.product-quantity .quantity .quantity-button.quantity-up {
    position: absolute;
    width: 26px;
    height: 100%;
    right: 0;
    top: 0;
}

.cart-table thead td.product-quantity .quantity .quantity-button.quantity-down {
    position: absolute;
    width: 26px;
    height: 100%;
    left: 0;
    top: 0;
}

.cart_totals {
    float: none;
    width: auto;
    border: 1px solid #e8e8e8;
    background: #f9f9f9;
    padding: 0 20px 20px;
}

.cart_totals h2 {
    font-size: 20px;
    line-height: 30px;
    padding: 20px;
    margin: 0;
    font-weight: 600;
    text-align: center;
}

.cart_totals .shop_table {
    text-align: left;
    width: 100%;
    border-radius: 0;
    border-collapse: separate;
    margin: 0 -1px 20px 0;
    padding: 0;
    border: none;
    background: #fff;
}

.cart_totals .shop_table .cart-subtotal th, .cart_totals .shop_table .cart-subtotal td {
    border-top: 0;
}



.cart_totals .shop_table th {
    width: 35%;
    vertical-align: top;
    line-height: 1.5em;
    border: none;
    background: 0 0;
    text-align: left;
    font-size: 18px;
    color: #323232;
    padding: 15px 20px;
    font-weight: 600;
    border-top: 1px solid #e8e8e8;
}

.cart_totals .shop_table td {
    font-weight: 600;
    color: #323232;
    text-align: right;
    font-size: 18px;
    border: none;
    /* padding: 20px; */
    line-height: 1.5em;
    vertical-align: top;
    border-top: 1px solid #e8e8e8;
}

.cart_totals .shop_table .order-total th {
    vertical-align: middle;
}

.cart_totals .proceed-to-checkout a.button {
    padding: 15px 20px;
    font-size: 15px;
    font-weight: 500;
    line-height: 26px;
    border-radius: 0;
    background: #323232;
    color: #fff;
    display: block;
    text-align: center;
    text-transform: uppercase;
}

.w_100 {
    /* display: block; */
    width: 100% !important;
    margin: 0;
}

.pd_none {
    padding: 0 !important;
}

.variants {
    display: inline-block;
    width: 100%;
    padding: 5px;
}

.variants span {
    display: inline-block;
    width: 50%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.8em;
}

.variants span.name {
    white-space: normal;
}

.variants span.left {
    text-align: left;
}
.cart-table tr {
    margin-bottom: 200px;
}

.special-ins {
    display: inline-block;
    width: 100%;
    padding: 5px;
    text-align: left;
}


.cart-table tr td.product-name {
    width: 100px;
}

@media screen and (max-width: 767px) {
    
    /* .w_100 {
        width: auto !important;
    } */

    .cart-table thead th {
        display: none;
    }
    .cart-table tr:first-child {
        margin-bottom: 0 !important;
        border-top: 0;
    }
/* 
    .cart-table tr:last-child {
        margin-bottom: 0 !important;
    } */
    .cart-table tr {
        padding-left: 120px;
        position: relative;
        display: block;

        margin-bottom: 1em;
        border-top: 1px solid #ebebeb;
        border-bottom-color: #ebebeb;
    }

    .cart-table tr td:not(:last-child) {
        border-style: solid;
        border-width: 0 0 1px 0;
    }

    .cart-table tr td.no-variant {
        border-style: none;
        padding: 0;
        margin: 0;
    }

    .cart-table tr td {
        text-align: right;
        padding-bottom: 10px;
        padding-top: 10px;
    }

    .cart-table tr td.res {
        display: block;
        clear: both;
    }

    .cart-table tr td[data-title]::before {
        font-size: 10px;
        text-transform: uppercase;
        color: #000000;
        font-weight: 400;
    }

    .cart-table tr td[data-title]::before {
        content: attr(data-title) ": ";
        font-weight: 600;
        float: left;
    }

    .cart-table tr td.product-remove {
        position: absolute;
        top: 0px;
        right: 20px;
        z-index: 1;
        margin-bottom: 0;
        padding-bottom: 0;
        border: none;
        font-size: 11px;
    }

    .cart-table tr td.product-thumbnail {
        position: absolute;
        left: 10px;
        top: 15px;
        padding: 0;
        border: none !important;
    }

    .cart-table tr td.product-name {
        text-align: left;
        border-width: 0;
        padding-right: 20px;
        padding-top: 30px;
        width: auto;
    }
}