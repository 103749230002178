.backdrop_edit {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100vw;
    z-index: 999999;
    background-color: rgba(0, 0, 0, 0.7);
    overflow: hidden;
    overflow-y: auto;
}

.edit_modal {
    width: 100%;
    background-color: #ffffff;
    padding: 10px 20px;
    z-index: 99999;
    max-width: 750px;
    margin: 0 auto;
}

/* .edit_modal input {
    width: 100%;
    border: none;
    border-bottom: 1px solid #000000;
}

.edit_modal input:focus {
    outline: none;
    border-bottom: 1px solid #cccccc;
} */








.edit_modal .group {
    display: block;
    position: relative;
    margin: 45px 0;
}
  
.edit_modal textarea {
    resize: none;
}
  
.edit_modal input,
.edit_modal textarea {
    background: none;
    color: #000000;
    font-size: 18px;
    padding: 10px 10px 10px 5px;
    display: block;
    width: 100%;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #c6c6c6;
}

.edit_modal input:focus,
.edit_modal textarea:focus {
    outline: none;
}

.edit_modal input:focus ~ label, .edit_modal input:valid ~ label,
.edit_modal textarea:focus ~ label,
.edit_modal textarea:valid ~ label {
    top: -14px;
    font-size: 12px;
    color: #2196F3;
}

.edit_modal input:focus ~ .bar:before,
.edit_modal textarea:focus ~ .bar:before {
    width: 100%;
}
  
.edit_modal input[type="password"] {
    letter-spacing: 0.3em;
}
  
.edit_modal label {
    color: #c6c6c6;
    font-size: 16px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 5px;
    top: 10px;
    -webkit-transition: 300ms ease all;
    transition: 300ms ease all;
}
  
.edit_modal .bar {
    position: relative;
    display: block;
    width: 100%;
}

.edit_modal .bar:before {
    content: '';
    height: 2px;
    width: 0;
    bottom: 0px;
    position: absolute;
    background: #2196F3;
    -webkit-transition: 300ms ease all;
    transition: 300ms ease all;
    left: 0%;
}

.edit_modal .btn-box {
    width: 100%;
    margin-bottom: 0 auto;
    text-align: center;
}

.edit_modal .btn-box .btn {
    margin: 0 10px;
}