.aboutus_container .section-title {
    padding-top: 80px;
    padding-bottom: 80px;
}

.aboutus_container .section-title h2 {
    text-align: center;
    margin-bottom: 8px;
    text-transform: capitalize;
}

.aboutus_container p {
    color: #969696;
}
