.about_container {
    position: relative;
}

.about_container::after {
    clear: both;
}

.image_session {
    width: 100%;
    height: 250px;
    display: inline-block;
    overflow: hidden;
    margin-bottom: -40px;
    background: url('../../assets/img/banner2.jpg');
    background-repeat: no-repeat;
    background-size: 100% 250px;
}

.image_session img {
    width: 100%;
    height: 250px;
}

.about_container .search_filter {
    position: relative !important;
}


.about_container .search_section {
    display: inline-block;
    width: 100%;
    padding: 10px 10px;
}

.about_container .search_section input {
    width: 100%;
    border: none;
    /* border-left: 1px solid #ccc; */
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    -webkit-box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    -webkit-appearance: none;    
}

/* .search_section input:hover, .search_section input:focus {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
} */

.about_container .search_section input:focus {
    outline: none;
}

.about_container .search-icon {
    display: inline-block;
    margin-left: -30px;
    color: rgba(0,0,0,0.5);
    cursor: pointer;
}
@media screen and (min-width: 768px) {
/* 
    .about_container {
        display: flex;
        align-items: center;
        width: 80%;
    }

    .about_container::before{
        clear: both;
    }

    .image_session {
        margin-bottom: 0;
        height: auto;
    } */

    
    .image_session {
        width: 100%;
        height: 400px;
        background: url('../../assets/img/banner.jpg');
        background-repeat: no-repeat;
        background-size: 100% 400px;
    }

    
    .image_session img {
        height: 400px;
    }
}

