.add-btn-wrapper {
    padding: 0 12px;
    position: fixed;
    width: 100%;
    max-width: 800px;
    height: 58px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background: #fff;
    -webkit-box-shadow: 0 -2px 8px 0 rgb(0 0 0 / 18%);
    box-shadow: 0 -2px 8px 0 rgb(0 0 0 / 18%);
    box-shadow: 7px 4px 8px 0 rgb(0 0 0 / 18%);
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    z-index: 9;
}

.add-address {
    position: relative;
    display: inline-block;
    font-weight: 700;
    white-space: nowrap;
    text-align: center;
    border: 1px solid #999;
    transition: all .3s cubic-bezier(.645,.045,.355,1);
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    box-sizing: border-box;
    padding: 3px 12px;
    font-size: 14px;
    line-height: 24px;
    border-radius: 16px;
    outline: 0;


    width: 94%;
    height: 40px;
    padding-top: 4px;
    padding-bottom: 4px;
    background: linear-gradient(90deg,#02c7b5,#009688);
    color: #fff;
    border-width: 0;
}

.add-address:active {
    background: linear-gradient(90deg, #00998b,#008c7f);
    color: #fff;
}

.addresses {
    padding-top: 90px;
    position: relative;
    width: 100%;
    padding-bottom: 60px;
    overflow-y: auto;
}

.address-list {
    padding: 0;
    min-height: 100%;
}

.address-list-item {
    margin-bottom: 12px;
    border: unset;
    display: block;
    width: 100%;
    margin-right: 0;
    box-shadow: 7px 4px 8px 0 rgb(0 0 0 / 18%);
}

.address-item-wrapper {
    display: flex;
    background-color: #fff;
}

.item-select {    
    display: flex;
    align-items: center;
    padding-left: 12px;
}

.custom-radio {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
    color: #222;
    font-size: 20px;
    line-height: 1.5;
    list-style: none;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0 8px 0 0;
    cursor: pointer;
    vertical-align: middle;
}

.custom-radio-checked>span:first-child.checked {
    color: #009688;
}

.custom-radio-checked .checked svg {
    font-size: 22px;
}

.custom-radio input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
}

.address-content-wrapper {
    border-radius: 8px;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    padding: 12px 10px 12px 5px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    position: relative;
}

.address-content {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    overflow: hidden;
    max-width: 290px;
    position: relative;
    cursor: pointer;
}

.address-content .name {
    font-weight: 700;
    font-size: 13px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.address-content .address-info {
    color: #666;
    line-height: 1.5;
}

.address-control {
    color: #999;
    width: 40px;
    display: flex;
}

.address-control-del-btn {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    cursor: pointer;
    margin-bottom: unset;
}

.address-control-del-btn span {
    color: #666;
    display: inline-block;
    line-height: 1.8;
    font-size: 20px;
    margin-left: 8px;
    color: red;
}

.custom-radio-circle {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
}