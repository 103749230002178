
/* For Shopping Cart Slide */
.site-header-cart-slide {
    position: fixed;
    top: 0;
    bottom: 0;
    overflow: hidden;
    overflow-y: auto;
    width: 300px;
    background-color: #fff;
    transition: transform .25s ease;
    display: flex;
    flex-direction: column;
    right: 0;
    z-index: 9999999;
    transform: translate3d(300px,0,0);
}

.cart-slide-heading {
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
    padding: 20px 15px 20px 15px;
    border-bottom: 1px solid;
    border-bottom-color: #ebebeb;
}

.cart-slide-title {
    flex: 1 1 auto;
    font-size: 20px;
    color: #000000;
    font-weight: 700;
    text-transform: uppercase;
}

.site-header-cart-slide .close-cart-slide {
    color: #000000;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: .3px;
    font-weight: 600;
    font-size: 13px;
    padding-right: 18px;
    position: relative;
    cursor: pointer;
}

.site-header-cart-slide .close-cart-slide:before {
    transform: rotate(45deg) !important;
}

.site-header-cart-slide .close-cart-slide:before, .site-header-cart-slide .close-cart-slide:after {
    content: " ";
    position: absolute;
    top: 50%;
    display: inline-block;
    margin-top: -1px;
    width: 12px;
    height: 2px;
    background-color: #000000;
    transition: background-color .2s ease-in-out, transform .2s ease-in-out, width .2s ease-in-out;
    right: 0;
    transform: rotate(-45deg);
}

.site-header-cart-slide.active {
    transform: none;
}

.site-header-cart-slide.active + .cart-slide-overlay {
    visibility: visible;
    opacity: 1;
}

.site-header-cart-slide .shopping_cart_slide {
    margin-bottom: 0;
    position: relative;
    flex: 1 1 auto;
    display: flex;
}

.site-header-cart-slide .shopping_cart_content {
    padding: 0 15px;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
}

.cart_empty {
    text-align: center;
    font-size: 18px;
    margin: 1.41575em;
    color: rgba(0, 0, 0, 0.7);
}

.mini_cart_scroll {
    position: relative;
    flex: 1 1 auto;
    overflow-y: auto;
}
.mini_cart_scroll::after {
    clear: both;
}

.mini_cart_list {
    position: absolute;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    overflow-x: hidden;
    margin-bottom: 0;
    margin-left: 0;
    list-style: none;
    padding: 0 20px;
}

.mini_cart_list li:first-child {
    padding-top: 1em;
}

.mini_cart_list li::before, .mini_cart_list li::after {
    content: "";
    display: table;
}

.mini_cart_item {
    position: relative;
    padding-left: 27px;
    display: block;
    font-size: 1em;
    margin: 10px 0;
}

.mini_cart_list span  {
    line-height: 1.41575em;
    color: #000;
}

.mini_cart_item span.remove {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    opacity: 1;
    font-size: 1.5em;
    color: #cccccc;
    cursor: pointer;
}


.mini_cart_item .mini_item_container span img {
    max-width: 60px;
    max-height: 60px;
    margin-right: 1em;
    float: left;
}

.mini_cart_list li:last-child {
    border-bottom: 0;
}

.mini_item_container {
    display: block;
}

.mini_item_container .img {
    width: 20%;
}

.mini_item_container span.product_name {
    width: 80%;
}

.tmp {
    display: block;
    overflow: hidden;
}

.tmp_price {
    color: #009688 !important;
}
/* .mini_item_container span {
    float: left;
} */

/* .quantity {
    display: block;
    width: 100%;
    color: #000000;
} */

.amount {
    color: #ef3636;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.mini_cart_list li::after {
    clear: both;
}

.mini_cart_total {
    margin-top: auto;
    padding: 1em;
    margin: 0;
    border-top: 1px solid;
    border-top-color: #ebebeb;
}

.mini_cart_total::before {
    content: "";
    display: table;
}

.mini_cart_total strong {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 18px;
    color: #000;
}

.mini_cart_total .subtotal_amount {
    color: #000;
    font-size: 1.41575em;
    font-weight: 700;
    float: right;
    overflow: hidden;
}

.mini_cart_buttons {
    padding: 0 0 1em;
    margin: 0;
    text-align: center;
    display: flex;
    flex-direction: column-reverse;
}

.mini_cart_buttons .button {
    border: 1px solid;
    margin-top: 15px;
    color: #fff;
    background-color: red;
    padding: 1em;
    margin: 5px 10px;
    font-size: 1em;
    font-weight: 800;
    border-radius: 5px;
}

.mini_cart_buttons .view_card {
    background-color: #fff !important;
    color: #000000 !important;
}


.cart-slide-overlay {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    bottom: 0;
    z-index: 997;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.7);
    visibility: hidden;
    transition: opacity .25s ease, visibility 0s ease;
}