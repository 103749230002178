.mini-shop-detail h2 {
    font-size: 1.2em;
    margin-bottom: 20px;
    color: #000000;
}

.mini-shop-detail p {
    display: block;
    font-size: 0.8em;
    font-weight: 400;
    line-height: 0.5em;
}

.mini-shop-detail .link {
    display: block;
    text-align: center;
}

.mini-shop-detail span {
    display: inline-block;
    width: 100%;
    text-align: left;
    font-size: 0.8em;
    font-weight: 400;
    margin-bottom: 20px;
}
