body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #cfdddd !important;
}

:root {
  background-color: #cfdddd;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bottom-space {
  padding-bottom: 80px !important;
}

@media (min-width: 726px) {
  .bottom-space {
    padding-bottom: 0;
  }
}

.screen_control {
  /* max-width: 800px; */
  margin: 0 auto;
  background-color: #fff;
  min-height: calc(93vh);
}
.__wrapper {
  position: relative;
  max-width: 800px;
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
  margin: 0 auto;
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
  /* box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px; */
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.modal {
  z-index: 999999 !important;
}