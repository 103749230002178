.shop_profile {
  padding: 5px;
  border: 1px solid #ececec;
  margin: 5px 2px 5px 2px;
  box-shadow: 0px 0px 6px 1px #dddddd;
  border-radius: 5px;
}

.shop_profile .card {
  border: none;
}

.shop_profile .card-body {
  padding: 10px 1.25em 6px 10px;
}

.shop_profile .detail_container {
  background-color: #ffffff;
  position: relative;
}

.shop_profile a.img-container {
  width: 100%;
  height: 0;
  position: relative;
  overflow: hidden;
  padding: 50% 0;
}

.shop_profile img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}

.shop_profile span {
  color: #cccccc;
  display: inline-block;
}

.shop_profile span.checked {
  color: orange;
}

.shop_title,
.shop_rating {
  font-size: 1em;
  color: black;
  font-weight: 600;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 0;
}

.shop_profile span i {
  display: inline-block;
  padding: 10px;
}

@media screen and (min-width: 786px) {
  .shop_profile img {
    width: 100%;
    height: 200px;
  }

  /* .shop_profile .detail_container {
        clear: both;
    }  */
}
