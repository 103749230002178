.product_detail_wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
}

.product_gallery {
  z-index: 9999;
  margin: 0;
}

.gallery_trigger {
  position: absolute;
  top: 25px;
  right: 25px;
  display: block;
  height: 45px;
  width: 45px;
  border-radius: 50%;
  z-index: 99;
  background: #fff;
  text-align: center;
  text-indent: -999px;
  overflow: hidden;
}

.product_img_container.active {
  width: 100%;
}

.gallery_trigger::before {
  font-family: 'Fontawesome';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 1;
  line-height: inherit;
  content: '\f065';
  display: block;
  line-height: 45px;
  text-indent: 0;
  color: #000;
  font-size: 19px;
}

.product_gallery_wrapper {
  border: 1px solid;
  color: #ebebeb;
  border: none;
  border-radius: 8%;
}

.product_img_container {
  position: relative;
  overflow: hidden;
  max-height: 277.891px;
}

.product_img_container img {
  width: 100%;
}

.product_summary {
  position: relative;
  background-color: #f4f4f4;
  padding: 25px 20px;
  border-radius: 5% 5% 0 0;
}

.product_summary_title {
  font-size: 1.3em;
  line-height: 1.2;
  margin-bottom: 0.2em;
  font-weight: bold;
}

.prop_title {
  margin-bottom: 0.3em !important;
}

p.price_wrapper {
  margin-bottom: 0.1em;
  font-weight: 700;
  display: flex;
  align-items: baseline;
  color: #ef3636;
}

p.price_wrapper span.price {
  font-size: 24px;
  padding-right: 5px;
}

p {
  margin-bottom: 2em;
}

.action {
  margin-bottom: 0;
  padding: 0.5em 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
}

.action .quantity {
  position: relative;
  display: inline-block;
  /* margin-top: 10px; */
  margin-right: 10px;
}

.quantity input:focus {
  outline: none;
}

.quantity input::-webkit-outer-spin-button,
.quantity input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.quantity input[type='number'] {
  -moz-appearance: textfield;
}

.action button {
  flex: 1;
  font-size: 18px;
  padding: 0.5em;
  /* margin-top: 10px; */
  white-space: nowrap;
  border: none;
  border-radius: 3px;
  background: none;
  background-color: #ef3636;
  border-color: #ef3636;
  color: #fff;
  cursor: pointer;
  text-decoration: none;
  font-weight: 700;
  text-shadow: none;
  -webkit-appearance: none;
}

.action button:focus {
  outline: none;
}

.wishlist_add {
  display: inline-block;
  margin-right: 20px;
  margin-top: 15px;
}

.wishlist_add span {
  font-weight: 700;
  color: #000;
  cursor: pointer;
}

.wishlist_add span::before {
  font-family: 'Fontawesome';
  content: '\f08a';
  display: inline-block;
  margin-right: 5px;
}

.product_meta {
  position: relative;
  margin: 0;
  padding: 0;
  border-top-color: #ebebeb;
  border-bottom-color: #ebebeb;
  padding-top: 10px;
}

/* .prop-single {
    margin-bottom: 2.5em !important;
} */

.properties span.prop {
  display: inline-block;
  padding: 5px 0;
}

.product_meta .product_meta_data_wrapper {
  display: block;
  font-size: 15px;
  margin: 8px 0;
  color: #969696;
}

.product_meta .product_meta_data_wrapper label {
  padding-right: 5px;
  margin-bottom: 0;
  color: #323232;
  font-weight: 600;
}

.image-gallery-content {
  top: 20%;
}

@media screen and (min-width: 768px) {
  .product_gallery {
    width: 100%;
    position: relative;
  }

  .product_meta {
    /* clear: both; */
    display: flex;
    flex-direction: column;
  }

  .single {
    max-width: 100% !important;
  }

  .image-gallery-content {
    top: 0;
  }
  .product_img_container {
    height: 100% !important;
    max-height: 100% !important;
  }
}

.property_wrapper {
  display: flex;
  align-items: center;
  padding: 15px 0;
  border-bottom: 1px solid #f4f4f4;
}

div.variant_title,
div.property_title {
  font-size: 1.2em;
  color: rgb(235, 183, 14);
  width: 20%;
}

.single {
  display: flex;
  width: 100%;
  /* margin: auto; */
  max-width: 24em;
  /* padding: 0.2em 0em 1.5em; */
  /* margin-bottom: 1em; */
  flex-wrap: wrap;
  padding-left: 10px;
}

.single:last-child {
  border: none;
}

div.property_title {
  margin-bottom: 0;
}
/* 
.property_wrapper .single {
  padding-bottom: 0.8em;
  padding-top: 0.8em;
  margin-bottom: 0.8em;
} */

.single label {
  /* width: 100%; */
  cursor: pointer;
  /* border: 1px solid #ccc; */
  /* padding: 10px; */
  /* border-radius: 10px; */
}

.single label {
  display: block;
  margin: 0 0.2em;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}

.single input {
  --bg: #e3e4e8;
  --fg: #17181c;
  --bs1: #ffffff;
  --bs2: #f3f4f8;
  --bs3: #cccdd1;
  --bs4: #c1c2c5;
  --transDur: 0.1s;
  font-size: calc(20px + (40 - 20) * (100vw - 320px) / (2560 - 320));
}

/* .single label:first-child input {
    border-radius: 0.5em 0 0 0.5em;
}
.single label:last-child input {
    border-radius: 0 0.5em 0.5em 0;
} */

.single input {
  opacity: 0;
  border-radius: 0.5em;
  box-shadow: 0.15em 0.15em 1em var(--bs2) inset,
    -0.15em -0.15em 1em var(--bs3) inset, 0.15em 0.15em 0.45em var(--bs4),
    -0.15em -0.15em 0.45em var(--bs1);
  cursor: pointer;
  display: block;
  margin-bottom: 0.5em;
  width: 100%;
  /* height: 1em; */
  transition: all var(--transDur) ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.single input:checked {
  box-shadow: -0.15em -0.15em 0.45em var(--bs2) inset,
    0.15em 0.15em 0.45em var(--bs4) inset, 0 0 0 var(--bs4), 0 0 0 var(--bs1);
  background-color: #009688;
}
.single input:checked + span {
  opacity: 1;
  /* color: #009688; */
  background-color: #009688;
  color: #fff;
}

span.color {
  width: 25px;
  height: 25px;
}

.single input:checked + span.color {
  /* border: 5px solid #ccc;
  border-radius: 50%; */
  box-shadow: 0px 0px 0px 5px;
}

.single input:focus {
  outline: transparent;
}

.single input + span {
  opacity: 0.65;
  transition: opacity var(--transDur) ease-in-out;
}

.single span.mini_title {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
}

.single_title {
  display: inline-block;
  padding: 10px 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
}

/* Dark mode */
@media (prefers-color-scheme: dark) {
  :root {
    --bg: #454954;
    --fg: #e3e4e8;
    --bs1: #5a5f6d;
    --bs2: #4a4e5a;
    --bs3: #3e424c;
    --bs4: #30333b;
  }
}

button.no_act {
  background-color: #ccc !important;
}

.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  z-index: 9;
}

.popup_inner {
  z-index: 9;
  position: absolute;
  left: 25%;
  right: 25%;
  top: 25%;
  /* bottom: 25%; */
  margin: auto;
  background: white;
  /* height: 200px; */
  padding: 20px;
}

.popup_inner .title {
  display: block;
  font-size: 2em;
  margin-bottom: 20px;
}

.popup_inner h1 {
  font-size: 1em;
}

.popup_inner div {
  display: block;
  padding: 20px;
  text-align: center;
  width: 100%;
}

.popup_inner div button {
  padding: 5px 10px;
  border: none;
  margin: 5px;
  border-radius: 5px;
}

.popup_inner div button.proceed {
  background-color: green;
  color: white;
}

.prop {
  display: inline-block;
  width: 100%;
}

.prop span {
  display: inline-block;
  font-size: 1em;
  width: 50%;
  vertical-align: text-top;
}

.quantity {
  position: relative;
  padding: 0 24px;
  display: inline-block;
  width: auto;
}

.quantity .screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  white-space: nowrap;
  height: 0;
  width: 1px;
  overflow: hidden;
  word-wrap: normal !important;
}

.quantity input {
  width: 50px !important;
  height: 45px;
  line-height: 1.65;
  float: left;
  display: block;
  padding: 0 10px;
  margin: 0;
  font-size: 16px;
  color: #969696;
  border: 1px solid #e6e6e6;
  background: #f8f8f8;
  text-align: center;
}

.quantity .quantity-button {
  position: relative;
  cursor: pointer;
  background: #fff;
  border: 1px solid #e6e6e6;
  width: 20px;
  text-align: center;
  color: #969696;
  font-size: 20px;
  font-family: 'Trebuchet MS', Helvetica, sans-serif !important;
  line-height: 45px;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.quantity .quantity-button.quantity-up {
  position: absolute;
  width: 26px;
  height: 100%;
  right: 0;
  top: 0;
}

.quantity .quantity-button.quantity-down {
  position: absolute;
  width: 26px;
  height: 100%;
  left: 0;
  top: 0;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: 100vh !important;
  /* border-radius: 5%; */
}

.choose-items {
  margin-bottom: 20px;
}

.choose-items .choose-items-title {
  font-size: 18px;
  line-height: 1.2;
  font-weight: 700;
  margin-bottom: 0.5em;
}

.choose-items .wrapper {
  overflow-x: auto;
  /* width: 100%; */
  display: flex;
}

.choice-item-btn {
  text-align: center;
  padding: 10px 20px;
  margin: 0 5px;
  border: none;
  cursor: pointer;
}

.choice-item-btn:focus {
  outline: none;
}

.choice-item-btn:hover {
  color: #009688;
}

.product_meta .loader {
  position: absolute;
  background-color: #ccc;
  opacity: 0.1;
  width: 100%;
  height: 100%;
  text-align: center;
  z-index: 999;
}

.product_meta .loader span {
  position: absolute;
  top: 50%;
}

.image-gallery-fullscreen-button {
  top: -90% !important;
}

.image-gallery-fullscreen-button:focus {
  outline: none !important;
}

.image-gallery-fullscreen-button:hover {
  background-color: transparent !important;
}


































.product-info {
  position: relative;
  margin: 0;
  padding: 0;
  padding: 0 16px 0px 16px;
}

.product-price {
  position: relative;
  background: rgb(255, 255, 255);
  min-height: 0.88rem;
}

.price-bar {
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  align-items: center;
}

span.price {
  font-weight: 700;
  font-size: 24px;
  color: #f57224;
}

span._discount {
  padding-left: 5px;
  font-size: 16px;
  font-weight: 500;
  color: #ef3636;
}

.sub-header {
  padding-bottom: 10px;
}

.sub-header h2 {
  margin: 0;
  color: rgba(0, 0, 0, 0.5);
  background-color: #fff;
  font-size: 14px;
  line-height: 1.25;
  font-weight: 400;
  word-break: break-word;
  word-wrap: break-word;
  overflow-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font-style: italic;
}

.divider-block {
  height: 10px;
  background-color: #f4f4f4;
}

.product-title {
  font-size: 1.3em;
  line-height: 1.2;
  margin-bottom: 0.2em;
  font-weight: bold;
  padding-top: 10px;
}


@media screen and (min-width: 768px) {

  
  .divider-block {
    border: 1px solid #f4f4f4;
    height: 0;
  }

  .product-info {
    display: flex;
    flex-direction: column;
  }

  .product_detail_wrapper {
    display: flex;
    padding: 20px 0;
    flex-direction: column;
  }
}