.fb_login {
    width: 100%;
    text-align: center;
    margin: 0 auto;
    padding: 20px;
}

.fb_login img {
    width: 100px;
    height: 100px;
    margin-bottom: 30px;
}

.fb_login h2 {
    font-size: 2em;
}

.fb_login p {
    color: #cccccc;
    font-weight: 600;
}

.login_phone_btn_wapper {
    margin-top: 10px;
}

.kep-login-facebook {
    width: 250px;
}

.login_phone_btn {
    border-radius: 0;
    font-family: Helvetica,sans-serif;
    font-weight: 700;
    -webkit-font-smoothing: antialiased;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: calc(.27548vw + 12.71074px);
    text-decoration: none;
    text-transform: uppercase;
    transition: background-color .3s,border-color .3s;
    background-color: #0097a7;
    border: calc(.06887vw + .67769px) solid #0097a7;
    padding: calc(.34435vw + 13.38843px) calc(.34435vw + 18.38843px);
    width: 250px;
}

.login_phone_btn:focus {
    border: none;
    outline: none;
}

.login_phone_btn:hover {
    background-color: #0097a7;
}