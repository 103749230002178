.mini_shop_list, .mini_item_list {
    margin: 5px 2px 5px 2px;
}

.search_container h2 {
    font-size: 1.3em;
    font-weight: 500;
}

.list_head h2 {
    display: inline-block;
    width: 70%;
}

.list_head a {
    display: inline-block;
    width: 30%;
    text-align: right;
    cursor: pointer;
    color: #009688;
}

.contents {
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-bottom: 20px;
}