/* @media screen and (min-width: 786px) {
    .home_container {
        display: flex;
        padding: 10px;
        justify-content: first baseline;
    }

    .home_container .product_list {
        display: flex;
    }
} */

.home_container {
  max-width: 2000px;
  margin: 0 auto;
}
