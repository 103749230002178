.profile_container {
    position: absolute;
    width: 100%;
    height: 200px;
    background-color: #534bae;
    /* max-width: 800px; */
}

.wrapper {
    position: absolute;
    width: 100%;
    /* max-width: 800px; */
}

.help_detail {
    position: relative;
    width: 90%;
    margin: 0 auto;
    border-radius: 10px;
    border: 1px solid #ccc;
    background-color: #ffffff;
    margin-top: 80px;
    padding: 20px;
    margin-bottom: 80px;
}

.help_detail span {
    display: inline-block;
}

.help_detail div.block {
    position: relative;
    display: block;
    border-bottom: 1px solid #000000;
}

.help_detail div.browse {
    border-bottom: none !important;
}

div.help_detail span.title {
    display: block;
    text-align: left;
}

.help_detail i {
    font-size: 1.8em;
    padding: 10px;
}

.help_detail .help_title {
    font-size: 1.2em;
    font-weight: 600;
}

.help_detail .list {
    display: block;
    padding: 10px;
}

.list .mini_title {
    font-weight: 500;
}

.list .data {
    color: teal;
}