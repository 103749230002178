.shop-detail-control {
  position: absolute;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  top: 0.12rem;
  right: 0.2rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.control-icon-wrapper {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 50%;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.control-icon-wrapper span {
  color: #999;
  font-size: 22px;
  font-weight: 700;
  overflow: hidden;
  display: inline-block;
}

.bh-table {
  width: 100%;
  margin: 15px 0;
}

.bh-date {
  font-weight: 600;
  width: 20%;
}

.bh-open {
  width: 40%;
  text-align: right;
  padding-right: 10px;
}

.bh-close {
  width: 40%;
}