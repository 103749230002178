.home_widget_container {
    width: 100%;
    padding: 5px 10px;
    /* border: 1px solid #ECECEC; */
    /* box-shadow: 1px 1px 4px 2px rgba(0, 0, 255, .2); */
}

.h_menu_item {
    padding: 20px;
}

.h_menu_item i {
    margin: 0;
    padding: 0;
    text-align: center;
    display: inline-block;
    padding: 10px;
    border-radius: 50%;
    background-color: #ccc;
}

.h_menu_item img{
    width: 24px;
    height: 24px;
}

.h_menu_item span {
    display: block;
    font-size: 12px;
    color: #000;
    margin: 0 5px;
}

.h_menu_item a:hover {
    text-decoration: none;
}

/* @media screen and (min-width: 786px) {
    .home_widget_container {
        display: flex;
    }

    .h_menu_item img{
        width: 35px;
        height: 35px;
    }
} */