.collection-wrapper {
    border: 0px solid black;
    position: relative;
    box-sizing: border-box;
    display: flex;
    -webkit-box-orient: vertical;
    flex-direction: column;
    align-content: flex-start;
    flex: 1 1 0%;
    background-color: rgb(239, 240, 245);
    -webkit-box-flex: 1;
    height: 100%;
}

.__icon {
    cursor: pointer;
    color: rgb(158, 158, 158);
    border: 0px solid black;
    position: relative;
    box-sizing: border-box;
}

.collection {
    border: 0px solid black;
    position: relative;
    box-sizing: border-box;
    display: flex;
    -webkit-box-orient: vertical;
    flex-direction: column;
    align-content: flex-start;
    flex-shrink: 0;
    padding: 20px;
}

.collected-name-wrapper {
    border: 0px solid black;
    position: relative;
    box-sizing: border-box;
    display: flex;
    -webkit-box-orient: horizontal;
    flex-direction: row;
    align-content: flex-start;
    flex-shrink: 0;
    padding: 0px 11.44px;
    height: 43.16px;
    -webkit-box-align: center;
    align-items: center;
    background-color: rgb(255, 255, 255);
}

.collection-name, .collection-shop-name {
    border: 0px solid black;
    position: relative;
    box-sizing: border-box;
    display: flex;
    -webkit-box-orient: horizontal;
    flex-direction: row;
    align-content: flex-start;
    flex: 1 1 0%;
    -webkit-box-flex: 1;
    -webkit-box-align: center;
    align-items: center;
}

.show-collections {
    border: 0px solid black;
    position: relative;
    box-sizing: border-box;
    display: block;
    -webkit-box-orient: vertical;
    flex-direction: column;
    align-content: flex-start;
    flex-shrink: 0;
    font-size: 19.24px;
    color: rgb(158, 158, 158);
}

.collection-shops {
    border: 0px solid black;
    position: relative;
    box-sizing: border-box;
    display: flex;
    -webkit-box-orient: vertical;
    flex-direction: column;
    align-content: flex-start;
    flex-shrink: 0;
    padding: 0px 17.16px;
    background-color: rgb(255, 255, 255);
}

.collection-shop {
    border-width: 0px 0px 1px;
    border-style: solid;
    border-color: black black rgb(218, 218, 218);
    border-image: initial;
    position: relative;
    box-sizing: border-box;
    display: flex;
    -webkit-box-orient: horizontal;
    flex-direction: row;
    align-content: flex-start;
    flex-shrink: 0;
    padding: 17.16px 0px;
    background-color: rgb(255, 255, 255)
}

.collection-shop-detail {
    border: 0px solid black;
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    -webkit-box-orient: vertical;
    align-content: flex-start;
    flex: 1 1;
    -webkit-box-flex: 1;
}

.delete-icon {
    border: 0px solid black;
    position: relative;
    box-sizing: border-box;
    display: block;
    -webkit-box-orient: vertical;
    flex-direction: column;
    align-content: flex-start;
    flex-shrink: 0;
    font-size: 26px;
    color: rgb(255, 0, 0);
}

.collection-delete-icon {
    font-size: 20px;
    padding: 0 20px;
    color: red;
}