.business_types ul li {
    list-style: none;
    display: inline-block;
    width: 100%;
    padding: 10px 20px;
    border-bottom: 1px solid #cccccc;
}

.business_types ul li:hover {
    background-color: #d8d5d560;
}

.business_types ul li a {
    color: #000000;
}

.business_types ul li a img {
    width: 24px;
    height: 24px;
}

.business_types ul li a span {
    display: inline-block;
    padding: 10px 20px;
}