.head {
    position: relative;
    display: block;
    padding: 10px;
    border: 1px solid #cccccc;
    margin: 15px 10px;
    border-radius: 10px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    -webkit-box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    -webkit-appearance: none;  
    cursor: pointer;  
    color: #000000;
}

.head span {
    display: inline-block;
    line-height: 1.2em;
}

.head .name {
    font-size: 1.3em;
    font-weight: 600;
    width: 100%;
}

.head .sale_no, .head .date {
    width: 100%;
}

.head .sale_no .title {
    font-size: 1.2em;
    font-weight: 500;
    padding-right: 10px;
}


.head .status {
    position: absolute;
    top: 5px;
    right: 10px;
}

.item_container, .foot {
    position: relative;
    display: block;
    padding: 10px;
    border: 1px solid #cccccc;
    margin: 15px 10px;
    border-radius: 10px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    -webkit-box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    -webkit-appearance: none;  
    cursor: pointer;  
    color: #000000;
}

.items {
    display: inline-block;
    width: 100%;
    padding: 5px;
}

.items span {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1em;
    text-align: right;
    vertical-align: top;
}

.items span.name {
    white-space: normal;
    width: 10%;
}

.items span.item {
    width: 50%;
}

.items span.price {
    width: 40%;
}

.items span.left {
    text-align: left;
}

.items span.inner {
    width: 100%;
    text-align: left;
    display: block;
}

.foot .total span {
    display: inline-block;
    width: 50%;
}

.foot .total span.title {
    font-size: 1.2em;
    font-weight: 500;
}

.right {
    text-align: right;
}