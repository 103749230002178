.sale_list_item {
    position: relative;
    display: block;
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    cursor: pointer;  
    color: #000000;
}

.sale_list_item .product-info {
    padding: 10px 16px;
}

.sale_list_item span {
    display: inline-block;
    line-height: 1.2em;
}

.sale_list_item .sale_no, .sale_list_item .date {
    width: 100%;
}

.sale_list_item .sale_no .title {
    font-size: 1.2em;
    font-weight: 700;
    padding-right: 10px;
}


.sale_list_item .status {
    position: absolute;
    top: 25px;
    right: 10px;
}