.wishlist_container {
    margin: 20px;
}

.wishlist_container h3 {
    font-size: 1.5em;
}

.wishlist_container ul {
    margin: 0;
    padding: 0;
}

.wishlist_container ul li {
    list-style: none;
    margin: 15px 0;
}


.wishlist_product_inner {
    border: 1px solid;
    border-color: #ebebeb;
    margin-left: -1px;
}

.wishlist_product_inner .product_head {
    position: relative;
    overflow: hidden;
    margin-bottom: 15px;
}

.wishlist_product_inner .product_head .product_image {
    width: 100%;
    min-width: 200px;
    max-height: 300px;
}

.wishlist_product_inner .product_head .product_image img {    
    display: block;
    margin: 0 auto;
    height: auto;
    max-width: 100%;
    border: none;
    -webkit-border-radius: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    transition: opacity 1.5s cubic-bezier(.19,1,.22,1) 0ms;
}

.wishlist_product_inner .wishlist_product_content {
    padding: 0 15px;
}

.wishlist_product_inner .wishlist_product_content .product_title {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: .5407911001em;
}

.wishlist_product_inner .wishlist_product_content span.price {
    color: #40c1c7;
}

.wishlist_product_inner .action {
    text-align: center;
    padding: 15px;
}

.wishlist_product_inner .action button {
    margin: 5px 3px;
    border: none;
    padding: 8px 15px;
    border-radius: 5px;
    color: #ffffff;
    cursor: pointer;
}

.wishlist_product_inner .action button:focus {
    outline: none;
}

.wishlist_product_inner .action button.add_to_cart {
    background-color: #009688;
}

.wishlist_product_inner .action button.add_to_wishlist {
    background-color: #007bff;
}

.wishlist_product_inner .action button.add_to_wishlist::before {
    font-family: 'Fontawesome';
    content: '\f08a';
}



@media screen and (min-width: 786px) {

    .wishlist_container ul li {
        display: inline-block;
        width: 25%;
        padding: 0 10px;
    }
    
    .wishlist_product_inner .product_head .product_image {
        width: 100%;
        height: 200px;
    }

    .wishlist_product_inner .product_head .product_image img {    
        display: block;
        margin: 0 auto;
        height: auto;
        max-width: 100%;
        border: none;
        -webkit-border-radius: 0;
        border-radius: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
        transition: opacity 1.5s cubic-bezier(.19,1,.22,1) 0ms;
    }

}












.wishlist-table thead td.product-add-to-cart .add_to_cart_button {
    font-weight: 500;
    line-height: 26px;
    background: #009688;
    color: #fff;
    display: block !important;
    margin: 0;
    text-align: center;
    padding: 5px 10px;
    font-size: 12px;
    border-radius: 3px;
    text-transform: uppercase;
    border-radius: 3px;
}