.checkout_container {
    position: relative;
    width: 100%;
}

.checkout_options,.delivary_address_wrapper {
    padding: 10px 20px;
}

.delivary_address_wrapper_inner {
    border: 1px solid #cccccc;
    border-radius: 10px;
    padding: 10px 20px;
}

.delivary_address_wrapper span {
    display: block;
}

.delivary_address_wrapper span.title {
    font-size: 1.3em;
    font-weight: 700;
}

.delivary_address_wrapper span.link {
    font-style: italic;
    font-size: 0.8em;
    color: #007bff;
    cursor: pointer;
}

.delivary_address_wrapper span.link:hover {
    text-decoration: underline;
}

.address_hover:hover {
    background-color: #007bff;
}

.checkout_options h3 {
    font-size: 1.3em;
}

.checkout_options .option {
    display: inline-block;
    padding: 10px;
}

.checkout_options .option h2 {
    font-size: 1.5em;
}
.checkout_options .option label{
    display: inline-block;
    padding-left: 10px;
    font-size: 1.2em;
}

.checkout_container .btn-box {
    width: 100%;
    text-align: center;
}

.checkout_container .btn-box button {
    padding: 10px 20px;
    margin: 10px;
}

select {
    position: relative;
    padding: 10px;
    min-width: 40%;
}

select:focus {
    outline: none;
}

.btn-box-2 {
    display: block;
    margin: 10px auto 0;
    text-align: center;
}

.btn-box-2 button {
    margin: 0 10px;
}